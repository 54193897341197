.DateRangePicker__CalendarDatePeriod--am {
     left: 0;
     /*background-color: white !important;*/
     right: 0% !important;
}

.DateRangePicker__CalendarDatePeriod--pm {
     left: 0% !important;
     /*background-color: white !important;*/
     right: 0;
}

.DateRangePicker__LegendItemColor--selection {
     background-color: blue; }

.sf-date-label {
    width: 100%;
    float: left;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    margin: 0px;
}

.MyDateRangePicker .small .icon {
    line-height: 50px;
    margin-top: -10px;
    margin-left: 10px;
    position: absolute;
    display: inline-block;
    font-size: 18px;
}

.MyDateRangePicker .small input {
    padding-left: 35px;
}

.modal-open {
  overflow-y: auto;
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e) center/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
}