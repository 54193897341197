.Reviews p,
dd {
  font-size: 1.25rem;
  font-weight: 300;
}

.Reviews li a {
  font-size: 1.25rem;
  padding-top: 1rem;
}

.Reviews .stars-text {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.Reviews .read-more-button {
  display: inline-block;
  margin-left: 1rem;
  font-weight: bold;
  color: #286DA8;
  cursor: pointer;
}

.Reviews .row {
  padding-top:.75rem;
  padding-bottom: 2rem;
}

.Reviews img {
    width: 100%;
    max-width:90px;
    height:auto;
}

.Reviews .reviewItemAvatar {
  display: flex;
  justify-content: flex-end;
}

.Reviews .reviewItemContent {
  padding-left: 1rem;
  font-size: smaller;
}

.Reviews .reviewItemContent p {
  font-size: 1rem;
}

.Reviews .reviewAuthor {
  font-size: 1.1rem;
  font-weight: bold;
}

.Reviews .reviewItemContentHeaderLeft {
  padding-bottom: 1rem;
}

.Reviews hr {
  max-width: 85%;
}

.Reviews .reviewsHeader {
  padding-bottom: 0px;
}

.Reviews .headerLeft {
  display: inline-block;
  padding-left:15px;
  padding-right:15px;
  border-right: medium solid lightgrey;
  height: 100%;
}

