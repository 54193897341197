@font-face {
    font-family: 'gunny_rewrittenregular';
    font-weight: normal;
    font-style: normal;
}
.Home {
  padding-bottom: 32px;
}
.Home h2 {
  text-align: center;
}
.Home .icon {
    line-height: 50px;
    margin-left: 10px;
    position: absolute;
    display: inline-block;
    font-size: 18px;
}
div.outer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
div.inner {
    flex-shrink: 0;
    width: 100%;
}
div.inner > img {
    width: 100%;
    height: auto;
    vertical-align: top;
}
div.inner > a > img {
    width: 100%;
    height: auto;
    vertical-align: top;
}
.searchform {
    border-radius: 10px;
    background-color: rgba(255,255,255,0.33);
    position: relative;
}
.Home input.left {
    padding: 0 5px 0 35px;
    background-color: #fff;
    border-right: 1px solid #d0d0d0;
    border-radius: 5px 0 0 5px;
}
.Home input.right {
    border-radius: 0 5px 5px 0;
}
.Home input {
    padding: 0 5px 0 35px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-width: 0;
    border-style: none;
    border-radius: 5px 0 0 5px;
    color: #464d55;
    font-size: 16px;
    font-weight: 500;
}
.sf-btn-label {
    width: 100%;
    float: left;
    vertical-align: middle;
    padding-left: 1px;
    margin: 0px;
}
.sf-btn {
    width: 100%;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
}
.btn-specials {
    color: #fff;
    border-color: #dc3545;
    background-color: #dc3545;
    font-weight: 700;
}
.btn-specials:hover {
    color: #fff;
    border-color: #b30000;
    background-color: #b30000;
    font-weight: 700;
}
p:empty {
    display: none;
}
.Home .reviews {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-shadow: 1px 1px 1px #2e2e2e, 1px -1px 1px #2e2e2e, -1px 1px 1px #2e2e2e, -1px -1px 1px #2e2e2e;
}

.Home .reviews-link {
    color: darkgray;
    /*font-size: 18px;*/
    font-weight: 500;
}