.App {
}

h1,h2,h3,h4,h5,h6 {
  color: #B49859;
}

.scroll {
  opacity: 0.6;
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 10px;
  border: none;
  line-height: 2.0;
  z-index: 99;
}
.scroll:hover {
    opacity: 1;
}

.arrow {
  position: relative;
  width: 1.2rem;
  height: 1.2rem;
  display: inline-block;
  z-index: 100;
}

.arrow:before,
.arrow:after {
    content:"";
    position: absolute;
    border-radius: 0.8rem;
    display: block;
   background-color: #404040;
}

.arrow:hover {
}

.arrow.up {
    height: 0.8em;
    margin-top: 0.5rem;
}
.arrow.up:before {
      left: 60%;
    }
.arrow.up:after {
      right: 55%;
    }
.arrow.up:before,
.arrow.up:after {
      top: -5%;
      height: 110%;
      width: 25%;
}
  

.arrow.up:before,
.arrow.up:after { transform: rotate(-40deg); }
.arrow.up:after { transform: rotate(40deg); }

.chgLng {
  display: block;
  position: absolute;
  top: 5px;
  right: 20px;
  line-height: 2.0;
  z-index: 99;
}
.chgLng a {
  opacity: 0.7;
  border-radius: 10px;
  border: none;
}
.chgLng a:hover {
    opacity: 1;
}

.chgLngSnd {
  display: block;
  position: absolute;
  top: 10px;
  right: 90px;
  line-height: 2.0;
  z-index: 99;
}
.chgLng a {
  opacity: 0.7;
  border-radius: 10px;
  border: none;
}
.chgSnd a:hover {
    opacity: 1;
}
.reviewStar {
  height: 20px;
  width: 20px;
  fill: gold;
}

.reviewStar-lg {
  height: 40px;
  width: 40px;
  fill: gold;
}

a.noHover:hover {
  text-decoration: none;
}

.CalendarDay__selected,
.CalendarDay__selected:hover,
.CalendarDay__selected:active
{
  background: #404040 !important;
  border-color: #404040 !important;
}

.CalendarDay__selected_span {
  background: #b49859 !important;
  border-color: #b49859 !important;
}
