.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem!important;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.navigation {
    background-color: #404040 !important;
}